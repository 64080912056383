const $$JustSymbol = Symbol()
const $$NothingSymbol = Symbol()

export const Just = x => ({
    inspect: () => `Just(${x})`,
    map: f => Maybe.of(f(x)),
    withDefault: _ => x,
    andThen: f => f(x),
    orElse: _ => Just(x),
    isJust: _ => true,
    isNothing: _ => false,
    [$$JustSymbol]: true,
    [$$NothingSymbol]: false
})

export const Nothing = _ => ({
    inspect: () => `Nothing`,
    map: _ => Nothing(),
    withDefault: a => a,
    andThen: _ => Nothing(),
    orElse: f => f(Nothing()),
    isJust: _ => false,
    isNothing: _ => true,
    [$$JustSymbol]: false,
    [$$NothingSymbol]: true
})

export const Maybe = {
    of: x =>
        x === null || x === undefined || x[$$NothingSymbol]
            ? Nothing()
            : Just(x)
}
