let observer = new MutationObserver(mutations => {
    mutations.forEach(function (mutation) {
        handleAutofocus(mutation.addedNodes)
    })
})

let target = document.querySelector("body")
let config = { childList: true, subtree: true }
observer.observe(target, config)

function handleAutofocus(nodeList) {
    for (var i = 0; i < nodeList.length; i++) {
        var node = nodeList[i]
        if (node instanceof Element && node.hasAttribute("data-autofocus")) {
            node.focus()
            if (node.select) node.select()
            break
        } else {
            handleAutofocus(node.childNodes)
        }
    }
}
